@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    height: 100%;
    margin: 0;
}
#root{
    height: 100%;
}
.smooth-scroll {
    scroll-behavior: smooth;
  }
  img {
    will-change: transform; /* Hint to the browser that transform will change */
  }
  
body {
    display: flex;
    flex-direction: column;
    background-color: rgb(243, 243, 243);
}
header, nav, footer {
    flex-shrink: 0;
}
main{
    flex-grow: 1;
}

/* Basic button styles */
.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
  }
  
  /* Hover effect */
  .button:hover {
    background-color: #2980b9;
  }
  
  /* Active (clicked) state */
  .button:active {
    background-color: #20729c;
  }
  
  /* You can add more styles as needed, such as margins, box-shadow, and more. */
  