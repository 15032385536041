.swiper {
  width: 100%;
  height: 100%;
  max-height: 560px;
}

.newsletter-text:hover{
  color:#192f59;
}

.textFamily{
  font-family: 'Philosopher', sans-serif;
  }
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide>img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.border-hover::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  top: 100%;
  left: 0;
  background: #b0e7ff;
  transition: transform 0.3s linear;
  transform: scaleX(0);
  transform-origin: right;
}

.border-hover:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.height-transition {
  transition-duration: 222ms !important;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.background-banner {
  background-image: url("https://t-hub.co/img/webp/governmentbannerbg1.webp"),
    url("https://t-hub.co/img/webp/governmentbannerbg2.webp");
  background-repeat: no-repeat;
  background-position: 99% 87%, 66% 1%;
}

@media (max-width: 898px) {
  
  .background-banner {
    background-position: 95% 35%, -3% 1%;
    background-size: 40%, 50%;
  }
  .vcimg {
    width: 43% !important;
  }
  .vctext {
    font-size: 13px;
    padding: 0;
    margin: 0;
  }
  .logo1 {
    width: 75% !important;
    padding-top: 64px !important;
  }
  .logo2 {
    width: 50% !important;
    padding-top: 69px !important;
  }
  .logo3 {
    width: 100% !important;
    padding-top: 148px !important;
    padding-left: 55px !important;
  }
  .logo4 {
    width: 100% !important;
    padding-top: 148px !important;
    padding-right: 69px !important;
  }
  .logo5 {
    width: 88% !important;
    padding-top: 123px !important;
    padding-left: 36px !important;
  }
  .logo6 {
    width: 98% !important;
    padding-top: 133px !important;
    padding-right: 36px !important;
  }
  .logo8 {
    width: 89% !important;
    padding-top: 54px !important;
    padding-right: 43px !important;
  }
  .logo7 {
    width: 69% !important;
    padding-top: 51px !important;
  }
  .logo9 {
    width: 95% !important;
    padding-left: 44px !important;
  }
  .logo10 {
    width: 92% !important;
    padding-top: 91px !important;
    padding-right: 51px !important;
  }
  .valued {
    display: flex !important;
    justify-content: center !important;
    font-size: 45px !important;
    font-weight: 600 !important;
    color: currentColor !important;
    color: #192f59;
  }
  .logo11 {
    width: 95% !important;
    padding-left: 44px !important;
  }
  .logo12 {
    width: 91% !important;
    padding-top: 105px !important;
    padding-right: 55px !important;
  }
}

@media (max-width: 420px) {
  .widthwork{
    width: 100% !important;
  }
  .valued {
    display: flex !important;
    justify-content: center !important;
    font-size: 23px !important;
    font-weight: 700 !important;
    margin-bottom: 16px !important;
    color: #192f59 !important;
  }
  .swiper{
    max-height: 160px;
  }
  .directormessage {
    font-size: 22px !important;
  }
  .logo1 {
    width: 40% !important;
  }
  .logo2 {
    width: 25% !important;
  }
  .logo3 {
    width: 67% !important;
    margin-top: 37px !important;
  }
  .logo4 {
    width: 67% !important;
    margin-top: 37px !important;
  }
  .logo5 {
    width: 60% !important;
  }
  .logo6 {
    width: 66% !important;
    margin-top: 17px !important;
  }
  .logo7 {
    width: 31% !important;
  }
  .logo8 {
    width: 36% !important;
  }
  .logo9 {
    width: 40% !important;
    margin-top: -19px !important;
  }
  .logo10 {
    width: 59% !important;
  }
  .logo11 {
    width: 39% !important;
  }
  .logo12 {
    width: 52% !important;
    margin-top: 13px !important;
  }
  .img11{
    width: 100% !important;
  }
}
@media (min-width: 998px) {
  .vcimg {
    max-width: 39% !important;
  }
  .logo1 {
    width: 93% !important;
    padding-top: 62px !important;
  }
  .logo12 {
    width: 70% !important;
    padding-top: 174px !important;
  }
  .logo11 {
    padding-top: 51px !important;
  }
  .logo10 {
    width: 81% !important;
    padding-top: 144px !important;
  }
  .logo2 {
    width: 60% !important;
    padding-top: 88px !important;
  }
  .logo3,
  .logo4 {
    width: 85% !important;
    padding-top: 169px !important;
  }
  .logo5,
  .logo6 {
    width: 74% !important;
    padding-top: 153px !important;
  }
  .logo7 {
    width: 74% !important;
    padding-top: 53px !important;
  }
  .logo8 {
    width: 78% !important;
    padding-top: 76px !important;
  }
  .logo9 {
    width: 75% !important;
    padding-top: 45px !important;
  }
  .valued {
    color: #192f59;
    display: flex !important;
    justify-content: center !important;
    font-size: 40px !important;
    font-weight: 700 !important;
    font-style: normal !important;
  }
}
.readmorebtn:hover {
  color: #212cc9;
  background-color: #fff;
  border-color: #212cc9;
}

@media (min-width:1024px) {
  .container{
    max-width: 1310px;
  }
}